.hero {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  width: 100%;
}
.hero .container {
  padding-top: 15%;
}
.hero h1 {
  color: #fff;
  font-size: 60px;
}
.hero p {
  color: #fff;
  opacity: 0.8;
}
form {
  background-color: #fff;
  border-radius: 5px;
  margin-top: 50px;
  padding: 0 20px;
}
form input {
  padding: 10px;
  width: 100%;
  border: 1px solid rgba(128, 128, 128, 0.2);
  margin-top: 5px;
  border-radius: 5px;
}
form span {
  font-size: 14px;
  color: grey;
}
input::placeholder {
  font-size: 17px;
  color: black;
}
form .box {
  padding: 15px;
  border-left: 1px solid rgba(128, 128, 128, 0.2);
}
form .box:nth-child(1) {
  border-left: none;
}
form h4 {
  font-weight: 500;
}
@media screen and (max-width: 800px) {
  .hero .container {
    padding-top: 30%;
  }
  form {
    display: flex;
    flex-wrap: wrap;
  }
  form .box {
    border-left: none;
  }
  form input {
    width: 100%;
  }
}

.home {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  color: #fff;
}
.homeContainer {
  position: relative;
  height: 82vh;
}
.coverImage {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: -2;
}
.coverImage img {
  width: 100vw;
  height: 100%;
  object-fit: cover;
}
.homeContainer .content {
  padding: 100px;

}
.homeContainer h1 {
  margin: 80px auto;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 800;
  color:'white';

}
.coverImage::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 88;
  background-image: linear-gradient(to right bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
}
.homeContainer .rating {
  margin: 30px 0;
}
.rating i {
  color: #e50813;
  margin-right: 5px;
}
.rating label {
  margin: 0 20px;
}
.rating span {
  background-color: #6c757d;
  padding: 5px;
  color: #fff;
  font-weight: bold;
}
.homeContainer .cast {
  margin: 30px 0;
}
.cast h4 {
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 17px;
}
.cast h4 span {
  color: #e50813;
}
.palyButton {
  display: flex;
  align-items: center;
  justify-content: center;
}
.palyButton .img {
  position: relative;
}
.change {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.palyButton img {
  width: 90px;
  height: 90px;
  margin-right: 20px;
}
.palyButton button:hover .change {
  opacity: 1;
}
.palyButton button {
  cursor: pointer;
  padding: 0;
  margin: 0;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 2px;
  transition: 0.5s;
}
.mragin {
  margin-top: 50%;
}
@media only screen and (max-width: 768px) {
  .homeContainer h1 {
    font-size: 50px;
    margin: 0;
  }
  .home {
    position: relative;
    margin: 0;
    height: 100vh !important;
  }
  .coverImage img {
    width: 100vw;
    height: 100vh !important;
  }
  .home .content {
    flex-direction: column;
    padding: 50px;
  }
  .home .row {
    width: 100%;
  }
  .home .palyButton {
    display: none;
  }
  .mragin {
    margin-top: 0% !important;
    background-color:#fbfeff  !important;
  }
}



.slick-slide{
  height: 90vh !important;
}






/*---------------global-------------*/
/*---------------control-btn-------------*/
.control-btn button {
  background: none;
  border: 2px solid rgba(255, 255, 255, 0.3);
  padding: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}
.control-btn .next {
  position: absolute;
  top: 50%;
  right: 2%;
}

.control-btn .prev {
  position: absolute;
  top: 50%;
  left: 2%;
  z-index: 99;
}
/*---------------control-btn-------------*/

