header {
  background-color: #fff;
  height: 10vh;
  box-shadow: 0 5px 30px rgb(0 22 84 / 10%);
  padding: 15px 0;
  position: sticky;
  top: 0;
  z-index: 99999;
}
header img {
  width: 170px;
}
header a {
  margin-left: 30px;
  transition: 0.5s;
  font-weight: 500;
}
header a:hover {
  color: orange;
}
.logo{
  margin:'1%';
  height: 50%;
}
header span {
  width: auto;
  padding: 0px 6px;
  background: #120d3f;
  border-radius: 50px;
  color: #ffffff;
  margin-right: 4px;
  font-weight: 400;
}
header h4 {
  font-weight: 500;
  margin-right: 20px;
}
header .toggle {
  display: none;
}

.nav{
  margin-right: 15%;
}
@media screen and (max-width: 768px) {
  header {
    padding: 0;
  }
  header img {
    margin-top: 30px;
  }
  header .button {
    display: none;
  }
  header .nav .flex {
    display: none;
  }
  header .toggle {
    display: block;
    margin-top: 20px;
  }
  header .small {
    position: absolute;
    top: 10vh;
    left: 0;
    width: 100%;
    background-color: #120d3f;
    padding: 20px;
  }
  header li a {
    display: inline-block;
    margin-bottom: 20px;
    color: #fff;
  }
}
