.team .box {
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid rgb(62 28 131 / 10%);
  padding: 2rem;
  transition: 0.5s;
}
.team .box:hover {
  box-shadow: 0 0 20px 0 rgb(112 121 138 / 18%);
  cursor: pointer;
}
.team .details {
  text-align: center;
  margin-top: 30px;
}
.team .img {
  width: 90px;
  height: 90px;
  margin: auto;
  position: relative;
}
.team img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}
.team .img i {
  color: #108be7;
  font-size: 17px;
  position: absolute;
  top: 35px;
  right: -10px;
}
.team .img::after {
  content: "";
  position: absolute;
  top: -15px;
  left: -15px;
  width: 110px;
  height: 110px;
  border: 5px solid #edf0f5;
  border-radius: 50%;
}
.team label {
  display: inline-block;
  margin-top: 20px;
}
.team i {
  color: grey;
  margin-right: 5px;
}
.team ul {
  display: inline-block;
  margin-top: 10px;
}
.team ul li {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  margin: 5px;
}
.team .button {
  margin-top: 20px;
}
.team .button i {
  color: white;
  font-size: 20px;
}

.btn3 {
  background: #ff6922;
  border-radius: 50px;
}

.background {
  padding: 80px 0;
  position: relative;
  background-color: white;
}

.container {
  max-width: 80%;
  margin: auto;
}

.mtop {
  margin-top: 50px;
}

.grid3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}

img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .grid4,
  .grid3,
  .grid5 {
    grid-template-columns: repeat(2, 1fr);
  }
  .heading {
    width: 100%;
  }
  .container {
    max-width: 90%;
  }
  .back::after,
  .back img,
  .back {
    height: 30vh;
  }
}

@media screen and (max-width: 600px) {
  .grid4,
  .grid3,
  .grid5 {
    grid-template-columns: repeat(1, 1fr);
  }
  .heading {
    width: 100%;
  }
  .container {
    max-width: 90%;
  }
  .back::after,
  .back img,
  .back {
    height: 30vh;
  }
}