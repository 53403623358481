.footerContact {
  background-color: #120d3f;
  padding: 40px 0;
  color: #fff;
}
.footerContact h1 {
  font-size: 40px;
}


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.App {

  margin:0;
	padding:0;
	box-sizing: border-box;
}


.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

*{
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}





.row{
  display: flex;
  flex-wrap: wrap;
}

.footer-col{
  color: #bbbbbb;
  text-align: left;
}

ul{
  list-style: none;
}
.footer{
  background-color:#120d3f ;
  margin: 0px;
  padding: 70px 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  width: 100%;
}

.footer-col{
  width: 25%;
  padding: 0 25px;
}

.footer-col h4{
  font-size: 18px;
  color: #ffffff;
  text-transform: capitalize;
  margin-bottom: 35px;
  font-weight: 500;
  position: relative;
  text-align: left;
}

.footer-col h4::before{
  content: '';
  position: absolute;
  left: 0;
  bottom: -10px;
  background-color: #efb42c;
  height: 2px;
  box-sizing: border-box;
  width: 50px;
}

.footer-col ul li:not(:last-child){
  margin-bottom: 10px;
}

.footer-col ul li a{
  font-size: 16px;
  text-transform: capitalize;
  color: #ffffff;
  text-decoration: none;
  font-weight: 300;
  color: #bbbbbb;
  display: block;
  transition: all 0.3s ease;
  text-align: left;
}

.footer-col ul li a:hover{
  color: #ffffff;
  padding-left: 8px;
}

.footer-col .social-links a{
  display: inline-block;
  height: 40px;
  width: 40px;
  color:#bbbbbb;
  margin: 0 10px 10px 0;
  transition: all 0.5s ease;
  line-height: 40px;
  left: 0;
}

.footer-col .social-links a:hover{
  color: #ffffff;
  padding-left: 8px;
}

.image{
  margin-top: 5%;
}

@media(max-width: 767px){
  .footer-col{
    width: 50%;
    margin-bottom:30px ;
  }

  .section{
    margin-top: 12%;
  }
}

@media(max-width: 574px){
  .footer-col{
    width: 100%;
  }
  .section{
    margin-top: 20%;
  }
}

.top-nav{
  margin-top: 6.5%;
}
